
.bm-view {
  width: 100%;
  height: 550px;
}

/*::v-deep .bm-view a {*/
/*  display: none;*/
/*}*/
.BMap_cpyCtrl,
.anchorBL {
  display: none;
}
